import { Filter } from '_global/Components/base/filter/FilterTypes';
import EWSFile from '_global/Components/base/uploader/utils/EWSFile';
import { LoadManagementTab } from 'AppContext/Apps/LoadManagement/Models/LoadManagement';
import {
    defaultCostumerEnergyResponse,
    ICostumerEnergyResponse,
    TabValue
} from 'AppContext/Apps/NetworkPowerQuality/CommercialQuality/Models/CommercialQuality';
import { ItemAccessGroup } from 'AppContext/UsersAndGroups/AccessGroups/Models/AccessGroupModel';
import { atom } from 'jotai';
import { AccessGroupsGridsResponse, Configuration } from 'logic/configuration/Configuration';
import { Group } from 'logic/misc/MapExtensions';
import { ConTestConfigTabValue } from 'models/conTest/ConTestConfiguration';
import { defaultDeviceValidationForm } from 'models/device/Device';
import MessageType from 'models/enums/MessageTypes';
import Mode from 'models/enums/ModeEnum';
import { Country } from 'models/layout/Country';
import { Profile } from 'models/layout/Profile';
import { RemoteUserSettings } from 'models/settings/RemoteUserSettings';
import { IValidation } from 'models/validation/validation';
import { defaultImportAssetValidation, defaultValidationFormAssets, IImportValidationAssets } from 'views/Assets/utils/AssetModel';
import { defaultValidationConfigurationProfile } from 'views/BulkConfiguration/services/Internal/ConfigurationProfileValidator';
import { defaultCompanyValidation } from 'views/Company/utils/CompanyModels';
import { defaultContactValidation } from 'views/Contacts/services/Internal/ContactValidator';
import { defaultConTestConfigValidation } from 'views/ConTestConfiguration/utils/ConTestConfigurationModels';
import { DashboardTab } from 'views/Dashboard/utils/models/Dashboard';
import { defaultOpAreaValidation } from 'views/OpAreas/services/Internal/OpAreaValidator';
import { defaultRegionsValidation } from 'views/Regions/services/Internal/RegionValidator';
import {
    defaultImportSIMInformationValidation,
    defaultValidationSimInformationForm,
    IImportValidation
} from 'views/SIMInformation/utils/SIMInformationModel';
import { IValidations } from 'views/Users/utils/UsersModels';

import { Tabs } from '../Tabs';

//------ GLOBAL USER PROPS --------//
const userAccessGroupResponseAtom = atom<AccessGroupsGridsResponse>({
    assets: [],
    workingOPArea: [],
    workingRegions: [],
    devices: []
});
const userProfileAtom = atom<Profile | undefined>(undefined);
const useConfigurationAtom = atom<Configuration | undefined>(undefined);
const userAccessGroupAtom = atom<ItemAccessGroup>({ id: 0, name: '' });
const userLanguageAtom = atom<string>('');
const flagShouldUpdateAtom = atom<RemoteUserSettings | undefined>(undefined);
const stateModalAtom = atom<{
    openModal: boolean;
    responseContent: string;
    responseTitle: string;
    type: MessageType;
    onOk?: () => void;
    onYes?: () => void;
    onNo?: () => void;
    onCancel?: () => void;
}>({
    openModal: false,
    responseContent: '',
    responseTitle: '',
    type: MessageType.INFO
});
const stateToastAtom = atom<{
    message: string;
    open: boolean;
    type: MessageType;
    onClose?: () => void;
}>({
    message: '',
    open: false,
    type: MessageType.SUCCESS
});

//------ DASHBOARD --------//
const subTabAtomDashboard = atom<string>(DashboardTab.OVERVIEW);
const reRenderMapAtomDashboard = atom(false);

//------ LOAD MANAGEMENT --------//
const subTabAtomLoadManagement = atom<string>(LoadManagementTab.TRANSFORMER_POWER);

//------ COMMERCIAL QUALITY --------//
const subTabAtomCommercialQuality = atom<string>(TabValue.FirstPage.toString());
const formValidAtom = atom<boolean>(false);
const costumerEnergyResponseAtom = atom<ICostumerEnergyResponse>(defaultCostumerEnergyResponse);
const setCostumerEnergyResponseAtom = atom(
    (get) => get(costumerEnergyResponseAtom),
    (_get, set, newSelectedReport: ICostumerEnergyResponse) => set(costumerEnergyResponseAtom, newSelectedReport)
);

//------ ASSETS --------//
const handleRefreshAssets = atom('');
const modeAtomAssets = atom<Mode>(Mode.LIST);
const tabAtomAssets = atom<string>(Mode.LIST);
const subTabAtomAssets = atom<string>('');
const validationAtomAssets = atom<IValidation[]>([...defaultValidationFormAssets]);
const validationSetAssets = atom(
    (get) => get(validationAtomAssets),
    (_get, set, newValidationSet: IValidation[]) => set(validationAtomAssets, newValidationSet)
);
const importAssetsValidationAtomAssets = atom<IImportValidationAssets>(defaultImportAssetValidation);
const hasLastDataAtomAssets = atom<boolean>(false);
const hasLastDataSet = atom(
    (get) => get(hasLastDataAtomAssets),
    (_get, set, newSelected: boolean) => set(hasLastDataAtomAssets, newSelected)
);
const filterAtomAssets = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const currentTransformersIdsAtom = atom<Array<number>>([]);

//------ CONFIGURATION PROFILE --------//
const handleRefreshConfigProfiles = atom('');
const modeAtomConfigurationProfile = atom<Mode>(Mode.LIST);
const validationAtomConfigurationProfile = atom<IValidations[]>(defaultValidationConfigurationProfile);
const filterAtomConfigurationProfile = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomConfigurationProfile = atom<string>(Mode.LIST);
const flagRevalidateFieldsAtom = atom<boolean>(false);

//------ CABLE TYPES --------//
const handleRefreshCableTypes = atom('');
const modeAtomCableTypes = atom<Mode>(Mode.LIST);
const filterAtomCableTypes = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomCableTypes = atom<string>(Mode.LIST);

//------ CONTACTS --------//
const handleRefreshContacts = atom('');
const modeAtomContacts = atom<Mode>(Mode.LIST);
const filterAtomContacts = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomContacts = atom<string>(Mode.LIST);
const selectAllContactsAtom = atom<
    {
        index: number;
        active: boolean;
        allSelected: boolean;
        selectedItems: boolean | Record<string, Record<string, boolean>>;
        selectedIds: number[];
    }[]
>([{ index: 1, active: true, allSelected: false, selectedItems: {}, selectedIds: [] }]);
const selectedContactsAtom = atom<{ all: boolean; filterQuery: string | undefined; ids: number[] }>({
    all: false,
    filterQuery: undefined,
    ids: []
});
const validationAtomContact = atom<IValidations[]>(defaultContactValidation);
const rowContactDeleteIdAtom = atom<number | undefined>(undefined);

//------ TEMPLATES --------//
const handleRefreshTemplates = atom('');
const modeAtomTemplates = atom<Mode>(Mode.LIST);
const filterAtomTemplates = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomTemplates = atom<string>(Mode.LIST);
const selectAllTemplatesAtom = atom<
    {
        index: number;
        active: boolean;
        isSelected: boolean;
        selectedItems: boolean | Record<string, Record<string, boolean>>;
    }[]
>([{ index: 1, active: true, isSelected: false, selectedItems: {} }]);

const flagSelectTotalItemsTemplatesAtom = atom<{ showPopup: boolean; flagSelectAll: boolean }>({
    showPopup: false,
    flagSelectAll: false
});

//------ COMPANY --------//
const handleRefreshCompany = atom('');
const handleRefreshCompanyRegions = atom('');
const modeAtomCompany = atom<Mode>(Mode.LIST);
const countryAtomCompany = atom<Country[]>([]);
const validationAtomCompany = atom<IValidations[]>(defaultCompanyValidation);
const validationCompanySet = atom(
    (get) => get(validationAtomCompany),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomCompany, newValidationSet)
);
const filterAtomCompany = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomCompany = atom<string>(Mode.LIST);

//------ EVENTS --------//
const modeAtomEvents = atom<Mode>(Mode.LIST);
const filterAtomEvents = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomEvents = atom<string>(Mode.LIST);

//------ SUBSCRIPTIONS --------//
const handleRefreshSubscriptions = atom('');
const modeAtomSubscriptions = atom<Mode>(Mode.LIST);
const filterAtomSubscriptions = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomSubscriptions = atom<string>(Mode.LIST);

//------ FIRMWARE --------//
const handleRefreshFirmware = atom('');
const handleRefreshFirmwareCompanies = atom('');
const modeAtomFirmware = atom<Mode>(Mode.LIST);
const fileAtom = atom<EWSFile | null>(null);
const validationAtomFirmware = atom<IValidation[]>([...defaultDeviceValidationForm]);
const validationSetFirmware = atom(
    (get) => get(validationAtomFirmware),
    (_get, set, newValidationSet: IValidation[]) => set(validationAtomFirmware, newValidationSet)
);
const filterAtomFirmware = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomFirmware = atom<string>(Mode.LIST);

//------ FIRMWARE TASKS--------//
const handleRefreshFirmwareTasks = atom('');
const handleRefreshFirmwareTaskDevices = atom('');
const handleRefreshFirmwareTaskOpAreas = atom('');
const handleRefreshFirmwareTaskRegions = atom('');
const handleRefreshFirmwareTaskAssets = atom('');
const handleRefreshFirmwareTaskDetails = atom('');
const handleRefreshFirmwareTaskPreview = atom('');
const modeAtomFirmwareTasks = atom<Mode>(Mode.LIST);
const validationAtomFirmwareTasks = atom<IValidation[]>([...defaultDeviceValidationForm]);
const validationSetFirmwareTasks = atom(
    (get) => get(validationAtomFirmwareTasks),
    (_get, set, newValidationSet: IValidation[]) => set(validationAtomFirmwareTasks, newValidationSet)
);
const tabAtomFirmwareTasks = atom<string>(Mode.LIST);
const editButtonAtomFirmwareTasks = atom<boolean>(false);
const filterAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAssetAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterCompaniesAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterRegionsAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterOpAreaAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterDevicesAtomFirmwareTasks = atom<Filter[]>([{ name: '', operator: [], value: [] }]);

//------ OPERATIONAL AREAS--------//
const handleRefreshOpAreas = atom('');
const handleRefreshOpAreaAssets = atom('');
const modeAtomOperationalAreas = atom<Mode>(Mode.LIST);
const validationAtomOperationalAreas = atom<IValidations[]>(defaultOpAreaValidation);
const validationOpAreaSet = atom(
    (get) => get(validationAtomOperationalAreas),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomOperationalAreas, newValidationSet)
);
const filterAtomOperationalAreas = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomOperationalAreas = atom<string>(Mode.LIST);
const selectAllOpAreasAtom = atom<
    {
        index: number;
        active: boolean;
        allSelected: boolean;
        selectedItems: boolean | Record<string, Record<string, boolean>>;
        selectedIds: number[];
    }[]
>([{ index: 1, active: true, allSelected: false, selectedItems: {}, selectedIds: [] }]);
const selectedOpAreasAtom = atom<{ all: boolean; filterQuery: string | undefined; ids: number[] }>({
    all: false,
    filterQuery: undefined,
    ids: []
});
//------ ALARMS --------//
const handleRefreshAlarms = atom('');

//------ REGIONS --------//
const handleRefreshRegions = atom('');
const handleRefreshRegionOpAreas = atom('');
const modeAtomRegions = atom<Mode>(Mode.LIST);
const countryAtomRegions = atom<Country[]>([]);
const validationAtomRegions = atom<IValidations[]>(defaultRegionsValidation);
const filterAtomRegions = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomRegions = atom<string>(Mode.LIST);
const selectAllRegionsAtom = atom<
    {
        index: number;
        active: boolean;
        allSelected: boolean;
        selectedItems: boolean | Record<string, Record<string, boolean>>;
        selectedIds: number[];
    }[]
>([{ index: 1, active: true, allSelected: false, selectedItems: {}, selectedIds: [] }]);
const selectedRegionsAtom = atom<{ all: boolean; filterQuery: string | undefined; ids: number[] }>({
    all: false,
    filterQuery: undefined,
    ids: []
});
const rowRegionDeleteIdAtom = atom<number | undefined>(undefined);
const openModalDeleteRegionAtom = atom<boolean>(false);

//------ REPORTS --------//
const handleRefreshReports = atom('');
const handleRefreshReportAccessGroups = atom('');
const handleRefreshReportAssets = atom('');
const handleRefreshReportCompanies = atom('');
const handleRefreshReportRegions = atom('');
const handleRefreshReportOpAreas = atom('');
const handleRefreshReportUsers = atom('');
const handleRefreshReportPreview = atom('');
const handleRefreshReportDetails = atom('');
const modeAtomReports = atom<Mode>(Mode.LIST);
const tabAtomReports = atom<string>(Mode.LIST);
const tabAtomSystemReports = atom<string>(Mode.LIST);
const tabAtomCustomReports = atom<string>(Mode.LIST);
const selectedReportAtom = atom<{ name: string; id: string } | undefined>(undefined);
const setSelectedReportAtom = atom(
    (get) => get(selectedReportAtom),
    (_get, set, newSelectedReport: { name: string; id: string } | undefined) => set(selectedReportAtom, newSelectedReport)
);
const selectedReportAtomSystem = atom<{ name: string; id: string } | undefined>(undefined);
const setSelectedReportAtomSystem = atom(
    (get) => get(selectedReportAtomSystem),
    (
        _get,
        set,
        newSelectedReport:
            | {
                  name: string;
                  id: string;
              }
            | undefined
    ) => set(selectedReportAtomSystem, newSelectedReport)
);
const selectedReportAtomCustom = atom<{ name: string; id: string } | undefined>(undefined);
const setSelectedReportAtomCustom = atom(
    (get) => get(selectedReportAtomCustom),
    (
        _get,
        set,
        newSelectedReport:
            | {
                  name: string;
                  id: string;
              }
            | undefined
    ) => set(selectedReportAtomCustom, newSelectedReport)
);
const updateReportsAtom = atom<boolean>(false);
const setUpdateReportAtom = atom(
    (get) => get(updateReportsAtom),
    (_get, set, updateReport: boolean) => set(updateReportsAtom, updateReport)
);
const updateReportsAtomSystem = atom<boolean>(false);
const setUpdateReportAtomSystem = atom(
    (get) => get(updateReportsAtomSystem),
    (_get, set, updateReport: boolean) => set(updateReportsAtomSystem, updateReport)
);
const updateReportsAtomCustom = atom<boolean>(false);
const setUpdateReportAtomCustom = atom(
    (get) => get(updateReportsAtomCustom),
    (_get, set, updateReport: boolean) => set(updateReportsAtomCustom, updateReport)
);
const filterAtomAllReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomSystemReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomCustomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAssetAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterCompaniesAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterRegionsAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterOpAreaAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAccessGroupsAtomReports = atom<Filter[]>([{ name: '', operator: [], value: [] }]);

//------ USER PROFILE --------//
const modeAtomUserProfile = atom<Mode>(Mode.PREVIEW);
const lastUserSettingsAtom = atom<RemoteUserSettings | undefined>(undefined);

//------ USER ROLES --------//
const handleRefreshUsersRoles = atom('');
const modeAtomUserRoles = atom<Mode>(Mode.LIST);
const filterAtomUserRoles = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomUserRoles = atom<string>(Mode.LIST);

//------ SIM INFORMATION --------//
const handleRefreshSimInfo = atom('');
const modeSimInformationAtom = atom<Mode>(Mode.LIST);
const tabSimInformationAtom = atom<string>(Mode.LIST);
const subTabSimInformationAtom = atom<string>('');
const validationSimInformationAtom = atom<IValidations[]>(defaultValidationSimInformationForm);
const validationSimInformationSet = atom(
    (get) => get(validationSimInformationAtom),
    (_get, set, newValidationSet: IValidations[]) => set(validationSimInformationAtom, newValidationSet)
);

const importSIMInformationValidationAtom = atom<IImportValidation>(defaultImportSIMInformationValidation);
const importSIMInformationValidationSet = atom(
    (get) => get(importSIMInformationValidationAtom),
    (_get, set, newSIMInformationValidationSet: IImportValidation) =>
        set(importSIMInformationValidationAtom, newSIMInformationValidationSet)
);

const hasLastDataAtom = atom<boolean>(false);
const filterSimInformationAtom = atom<Filter[]>([{ name: '', operator: [], value: [] }]);

//------ CONTEST CONFIGURATIONS --------//
const handleRefreshContestConfig = atom('');
const handleRefreshContestDevices = atom('');
const handleRefreshContestSIMs = atom('');
const handleRefreshContestResults = atom('');
const modeAtomConTestConfig = atom<Mode>(Mode.LIST);
const filterAtomConTestResults = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomConTestConfig = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomBatchDevices = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const filterAtomBatchSIMs = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomConTestConfig = atom<string>(Mode.LIST);
const subTabAtomConTestConfig = atom<ConTestConfigTabValue>(ConTestConfigTabValue.INFO);
const validationAtomConTestConfig = atom<IValidations[]>(defaultConTestConfigValidation);
const validationConTestConfigSet = atom(
    (get) => get(validationAtomConTestConfig),
    (_get, set, newValidationSet: IValidations[]) => set(validationAtomConTestConfig, newValidationSet)
);
const ConTestConfigQRCodeAtom = atom<boolean>(false);

//------ SIMULATOR --------//
const modeAtomSimulator = atom<Mode>(Mode.LIST);
const filterAtomSimulator = atom<Filter[]>([{ name: '', operator: [], value: [] }]);
const tabAtomSimulator = atom<string>(Mode.LIST);

const generalTabs = atom(new Group() as Tabs);

export {
    ConTestConfigQRCodeAtom,
    countryAtomCompany,
    countryAtomRegions,
    currentTransformersIdsAtom,
    editButtonAtomFirmwareTasks,
    fileAtom,
    filterAccessGroupsAtomReports,
    filterAssetAtomFirmwareTasks,
    filterAssetAtomReports,
    filterAtomAllReports,
    filterAtomAssets,
    filterAtomBatchDevices,
    filterAtomBatchSIMs,
    filterAtomCableTypes,
    filterAtomCompany,
    filterAtomConfigurationProfile,
    filterAtomContacts,
    filterAtomConTestConfig,
    filterAtomConTestResults,
    filterAtomCustomReports,
    filterAtomEvents,
    filterAtomFirmware,
    filterAtomFirmwareTasks,
    filterAtomOperationalAreas,
    filterAtomRegions,
    filterAtomSimulator,
    filterAtomSubscriptions,
    filterAtomSystemReports,
    filterAtomTemplates,
    filterAtomUserRoles,
    filterCompaniesAtomFirmwareTasks,
    filterCompaniesAtomReports,
    filterDevicesAtomFirmwareTasks,
    filterOpAreaAtomFirmwareTasks,
    filterOpAreaAtomReports,
    filterRegionsAtomFirmwareTasks,
    filterRegionsAtomReports,
    filterSimInformationAtom,
    flagRevalidateFieldsAtom,
    flagSelectTotalItemsTemplatesAtom,
    flagShouldUpdateAtom,
    formValidAtom,
    generalTabs,
    handleRefreshAlarms,
    handleRefreshAssets,
    handleRefreshCableTypes,
    handleRefreshCompany,
    handleRefreshCompanyRegions,
    handleRefreshConfigProfiles,
    handleRefreshContacts,
    handleRefreshContestConfig,
    handleRefreshContestDevices,
    handleRefreshContestResults,
    handleRefreshContestSIMs,
    handleRefreshFirmware,
    handleRefreshFirmwareCompanies,
    handleRefreshFirmwareTaskAssets,
    handleRefreshFirmwareTaskDetails,
    handleRefreshFirmwareTaskDevices,
    handleRefreshFirmwareTaskOpAreas,
    handleRefreshFirmwareTaskPreview,
    handleRefreshFirmwareTaskRegions,
    handleRefreshFirmwareTasks,
    handleRefreshOpAreaAssets,
    handleRefreshOpAreas,
    handleRefreshRegionOpAreas,
    handleRefreshRegions,
    handleRefreshReportAccessGroups,
    handleRefreshReportAssets,
    handleRefreshReportCompanies,
    handleRefreshReportDetails,
    handleRefreshReportOpAreas,
    handleRefreshReportPreview,
    handleRefreshReportRegions,
    handleRefreshReports,
    handleRefreshReportUsers,
    handleRefreshSimInfo,
    handleRefreshSubscriptions,
    handleRefreshTemplates,
    handleRefreshUsersRoles,
    hasLastDataAtom,
    hasLastDataSet,
    importAssetsValidationAtomAssets,
    importSIMInformationValidationSet,
    lastUserSettingsAtom,
    modeAtomAssets,
    modeAtomCableTypes,
    modeAtomCompany,
    modeAtomConfigurationProfile,
    modeAtomContacts,
    modeAtomConTestConfig,
    modeAtomEvents,
    modeAtomFirmware,
    modeAtomFirmwareTasks,
    modeAtomOperationalAreas,
    modeAtomRegions,
    modeAtomReports,
    modeAtomSimulator,
    modeAtomSubscriptions,
    modeAtomTemplates,
    modeAtomUserProfile,
    modeAtomUserRoles,
    modeSimInformationAtom,
    openModalDeleteRegionAtom,
    reRenderMapAtomDashboard,
    rowContactDeleteIdAtom,
    rowRegionDeleteIdAtom,
    selectAllContactsAtom,
    selectAllOpAreasAtom,
    selectAllRegionsAtom,
    selectAllTemplatesAtom,
    selectedContactsAtom,
    selectedOpAreasAtom,
    selectedRegionsAtom,
    selectedReportAtom,
    setCostumerEnergyResponseAtom,
    setSelectedReportAtom,
    setSelectedReportAtomCustom,
    setSelectedReportAtomSystem,
    setUpdateReportAtom,
    setUpdateReportAtomCustom,
    setUpdateReportAtomSystem,
    stateModalAtom,
    stateToastAtom,
    subTabAtomAssets,
    subTabAtomCommercialQuality,
    subTabAtomConTestConfig,
    subTabAtomDashboard,
    subTabAtomLoadManagement,
    subTabSimInformationAtom,
    tabAtomAssets,
    tabAtomCableTypes,
    tabAtomCompany,
    tabAtomConfigurationProfile,
    tabAtomContacts,
    tabAtomConTestConfig,
    tabAtomCustomReports,
    tabAtomEvents,
    tabAtomFirmware,
    tabAtomFirmwareTasks,
    tabAtomOperationalAreas,
    tabAtomRegions,
    tabAtomReports,
    tabAtomSimulator,
    tabAtomSubscriptions,
    tabAtomSystemReports,
    tabAtomTemplates,
    tabAtomUserRoles,
    tabSimInformationAtom,
    useConfigurationAtom,
    userAccessGroupAtom,
    userAccessGroupResponseAtom,
    userLanguageAtom,
    userProfileAtom,
    validationAtomConfigurationProfile,
    validationAtomContact,
    validationAtomRegions,
    validationCompanySet,
    validationConTestConfigSet,
    validationOpAreaSet,
    validationSetAssets,
    validationSetFirmware,
    validationSetFirmwareTasks,
    validationSimInformationSet
};
