import { ProcessedCompany } from 'models/companys/ProcessedCompany';
import { IValidations } from 'views/Users/utils/UsersModels';

import { IEnterprise } from '../Models/EnterpriseModel';

export interface ICompany {
    selectedCompany?: ProcessedCompany;
    enterprise?: IEnterprise;
}

export const defaultCompanyValidation = [
    { name: 'country', error: false, message: '' },
    { name: 'enterpriseId', error: false, message: '' },
    { name: 'name', error: false, message: '' }
] as IValidations[];
