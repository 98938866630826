import { InstallationPoint } from './InstallationPointsModel';

export interface IOpAreas {
    id: number;
    name: string;
    code: string;
    regionId: number;
    gpsPoints: InstallationPoint[];
}

export enum OpAreaModelKeys {
    id = 'id',
    name = 'name',
    code = 'code',
    regionId = 'regionId',
    gpsPoints = 'gpsPoints'
}
