import { InstallationPoint } from 'views/OpAreas/Models/InstallationPointsModel';
import { IOpAreas, OpAreaModelKeys } from 'views/OpAreas/Models/OpAreasModel';
import { IValidations } from 'views/Users/utils/UsersModels';

export const defaultOpAreaValidation = [
    { name: OpAreaModelKeys.name, error: false, message: '' },
    { name: OpAreaModelKeys.code, error: false, message: '' },
    { name: OpAreaModelKeys.regionId, error: false, message: '' },
    { name: OpAreaModelKeys.gpsPoints, error: false, message: '' }
] as IValidations[];

const isNullOrWhiteSpace = (text: string): boolean => {
    return !text?.trim();
};

const isDifferentFromZero = (text: number): boolean => {
    return text === 0;
};

const sizeGreaterThanZero = (gpsPoints: Array<InstallationPoint>): boolean => {
    return gpsPoints.length <= 0;
};

export const ValidateOpAreaValue = (
    array: IValidations[],
    id: OpAreaModelKeys,
    value?: string | null | number | Array<InstallationPoint>
): IValidations => {
    const validateValues = () => {
        let error = false;
        switch (id) {
            case OpAreaModelKeys.name:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case OpAreaModelKeys.code:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return { error: error, message: error ? `${id}_required` : '' };
            case OpAreaModelKeys.regionId:
                error = isDifferentFromZero((value as number) ?? 0);
                return { error: error, message: error ? `${id}_required` : '' };
            case OpAreaModelKeys.gpsPoints:
                error = sizeGreaterThanZero((value as Array<InstallationPoint>) ?? []);
                return { error: error, message: error ? `${id}_required` : '' };

            default:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return { error: error, message: error ? `${id}_required` : '' };
        }
    };

    return (array[array.findIndex((x) => x.name == id)] = {
        ...array[array.findIndex((x) => x.name == id)],
        error: validateValues().error,
        message: validateValues().message
    });
};

export function ValidateOpArea(opArea: IOpAreas): IValidations[] {
    for (const [key, value] of Object.entries(opArea).filter(([key]) => key !== 'id')) {
        ValidateOpAreaValue(defaultOpAreaValidation, key as OpAreaModelKeys, value ? (value as string) : null);
    }
    return defaultOpAreaValidation;
}

export function isOpAreaValid(opArea: IOpAreas): boolean {
    return ValidateOpArea(opArea).filter((x) => x.error).length === 0;
}
