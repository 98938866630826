import { IOpAreas } from 'views/OpAreas/Models/OpAreasModel';

import { InstallationPoint } from './InstallationPointsModel';

export interface IRegion {
    id: number;
    name: string;
    code: string;
    companyId: number;
    gpsPoints: InstallationPoint[];
    operationalAreas: IOpAreas[];
}

export enum RegionModelKeys {
    id = 'id',
    name = 'name',
    code = 'code',
    gpsPoints = 'gpsPoints',
    operationalAreas = 'operationalAreas',
    companyId = 'companyId'
}
