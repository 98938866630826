import { InstallationPoint } from 'views/Regions/Models/InstallationPointsModel';
import { IRegion, RegionModelKeys } from 'views/Regions/Models/RegionModel';
import { IValidations } from 'views/Users/utils/UsersModels';

export const defaultRegionsValidation = [
    { name: RegionModelKeys.name, error: false, message: '' },
    { name: RegionModelKeys.code, error: false, message: '' },
    { name: RegionModelKeys.companyId, error: false, message: '' },
    { name: RegionModelKeys.gpsPoints, error: false, message: '' }
] as IValidations[];

const isNullOrWhiteSpace = (text: string): boolean => {
    return !text?.trim();
};

const isDifferentFromZero = (text: number): boolean => {
    return text === 0;
};

const sizeGreaterThanZero = (gpsPoints: Array<InstallationPoint>): boolean => {
    return gpsPoints.length <= 0;
};

export const ValidateRegionValue = (
    array: IValidations[],
    id: RegionModelKeys,
    value?: string | null | number | Array<InstallationPoint>
): IValidations => {
    const validateValues = () => {
        let error = false;

        switch (id) {
            case RegionModelKeys.name:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };

            case RegionModelKeys.code:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return { error: error, message: error ? `${id}_required` : '' };

            case RegionModelKeys.companyId:
                error = isDifferentFromZero((value as number) ?? 0);
                return { error: error, message: error ? `${id}_required` : '' };

            case RegionModelKeys.gpsPoints:
                error = sizeGreaterThanZero((value as Array<InstallationPoint>) ?? []);
                return { error: error, message: error ? `${id}_required` : '' };
            case RegionModelKeys.operationalAreas:
                error = sizeGreaterThanZero((value as Array<InstallationPoint>) ?? []);
                return { error: error, message: error ? `${id}_required` : '' };

            default:
                error = isNullOrWhiteSpace((value as string) ?? '');
                return { error: error, message: error ? `${id}_required` : '' };
        }
    };

    return (array[array.findIndex((x) => x.name == id)] = {
        ...array[array.findIndex((x) => x.name == id)],
        error: validateValues().error,
        message: validateValues().message
    });
};

export function ValidateRegion(opArea: IRegion): IValidations[] {
    for (const [key, value] of Object.entries(opArea).filter(([key]) => key !== 'id')) {
        ValidateRegionValue(defaultRegionsValidation, key as RegionModelKeys, value ? (value as string) : null);
    }
    return defaultRegionsValidation;
}

export function isRegionValid(opArea: IRegion): boolean {
    return ValidateRegion(opArea).filter((x) => x.error).length === 0;
}
