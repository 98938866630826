import { Server_Type } from 'gql/graphql';
import {
    ConfigurationProfileModelKeys,
    EmailServerType,
    EncryptionType,
    IConfigurationProfile,
    TimerServerType
} from 'views/BulkConfiguration/Models/ConfigurationProfileModel';
import { InstallationPoint } from 'views/OpAreas/Models/InstallationPointsModel';
import { IValidations } from 'views/Users/utils/UsersModels';

export const defaultValidationConfigurationProfile = [
    { name: 'name', error: false, message: '' },
    { name: 'aquisitionRateEarth', error: false, message: '' },
    { name: 'communicationRateEarth', error: false, message: '' },
    { name: 'aquisitionRate', error: false, message: '' },
    { name: 'communicationRate', error: false, message: '' },
    { name: 'bluetoothTimeout', error: false, message: '' },
    { name: 'serverUrl', error: false, message: '' },
    { name: 'timeServerPort', error: false, message: '' },
    { name: 'timeServerProtocol', error: false, message: '' },
    { name: 'timeServerUrl', error: false, message: '' },
    { name: 'timeServerType', error: false, message: '' },
    { name: 'protectionEarthFrequencyF1', error: false, message: '' },
    { name: 'protectionEarthFrequencyF2', error: false, message: '' },
    { name: 'protectionEarthFrequencyF3', error: false, message: '' },
    { name: 'serviceEarthFrequencyF1', error: false, message: '' },
    { name: 'serviceEarthFrequencyF2', error: false, message: '' },
    { name: 'serviceEarthFrequencyF3', error: false, message: '' },
    { name: 'dnsServerName1', error: false, message: '' },
    { name: 'proxyUrl', error: false, message: '' },
    { name: 'proxyPort', error: false, message: '' },
    { name: 'emailServerType', error: false, message: '' },
    { name: 'emailServerUrl', error: false, message: '' },
    { name: 'serverEmailPort', error: false, message: '' },
    { name: 'emailUsername', error: false, message: '' },
    { name: 'encryptionType', error: false, message: '' },
    { name: 'emailSubjectExpression', error: false, message: '' },
    { name: 'emailBodyExpression', error: false, message: '' },
    { name: 'emailPassword', error: false, message: '' },
    { name: 'checkIntervalEmail', error: false, message: '' },
    { name: 'maxChecksEmail', error: false, message: '' },
    { name: 'emailEnabled', error: false, message: '' }
] as IValidations[];

export const MIN_VALUE = 1000;
export const MAX_VALUE = 3000;

export const MIN_VALUE_CHECKS = 1;
export const MAX_VALUE_CHECKS = 3600;
export const MAX_VALUE_PORT = 10000;

export const defaultValuesServerType = [
    { id: EmailServerType.POP, value: Server_Type.Pop },
    { id: EmailServerType.IMAP, value: Server_Type.Imap }
];

export const defaultValuesEncryptionType = [
    { id: EncryptionType.TLS, value: EncryptionType.TLS },
    { id: EncryptionType.SSL, value: EncryptionType.SSL }
];

const isNull = (text: number | null): boolean => {
    return text === null;
};

const isNullOrZero = (text: number | null): boolean => {
    return !text || text === 0;
};

const isNullOrEmptyString = (text: string | null): boolean => {
    return !text || text === '';
};

const isDeepgridNullorDeepgridZero = (text: number | null, timeType: TimerServerType): boolean => {
    return (timeType !== TimerServerType.DEEPGRID && !text) || (timeType !== TimerServerType.DEEPGRID && text === 0);
};

const isDeepgridNullorDeepgridEmpty = (text: string | null, timeType: TimerServerType): boolean => {
    return (timeType !== TimerServerType.DEEPGRID && !text) || (timeType !== TimerServerType.DEEPGRID && text === '');
};

const isGreaterThanOrLowerThan = (text: number): boolean => {
    return text < MIN_VALUE || text > MAX_VALUE;
};

const isEmailEnabledAndEmptryString = (text: string, emailEnabled: boolean): boolean => {
    return emailEnabled && text === '';
};

const isGreaterThanLowerThanChecks = (text: number | null, emailEnabled: boolean): boolean => {
    return (
        (emailEnabled && text !== null && (text < MIN_VALUE_CHECKS || text > MAX_VALUE_CHECKS)) ||
        (text !== null && (text < MIN_VALUE_CHECKS || text > MAX_VALUE_CHECKS)) ||
        (emailEnabled && text === null)
    );
};

const isProxyEnabledGreaterLowerThan = (text: number | null, proxyEnabled: boolean): boolean => {
    return ((text !== null && (text < 0 || text > 65535)) || text === null) && proxyEnabled === true;
};

const isProxyEnabledStringEmpty = (text: string | null, proxyEnabled: boolean): boolean => {
    return (!text || text?.trim() === '') && proxyEnabled === true;
};

const isDnsEnabledStringEmpty = (text: string | null, customDnsEnabled: boolean): boolean => {
    return (!text || text?.trim() === '') && customDnsEnabled === true;
};

const isEmailEnabledServerTypeExist = (text: number | null, emailEnabled: boolean): boolean => {
    return emailEnabled && (text === null || defaultValuesServerType.find((el) => el.id === text) === undefined);
};

const isEmailEnabledEncryptionTypeExist = (text: number | null, emailEnabled: boolean): boolean => {
    return emailEnabled && (text === null || defaultValuesEncryptionType.find((el) => el.id === text) === undefined);
};

export const ValidateConfigurationProfileValue = (
    array: IValidations[],
    id: ConfigurationProfileModelKeys,
    value?: string | null | number | Array<InstallationPoint>,
    timeType?: TimerServerType,
    emailEnabled?: boolean,
    proxyEnabled?: boolean,
    customDnsEnabled?: boolean
): IValidations => {
    const validateValues = () => {
        let error = false;
        switch (id) {
            case ConfigurationProfileModelKeys.name:
                error = isNullOrEmptyString((value as string) ?? '');
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.aquisitionRateEarth:
                error = isNull((value as number) ?? null);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.aquisitionRate:
                error = isNull((value as number) ?? null);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.communicationRate:
                error = isNullOrZero((value as number) ?? null);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.communicationRateEarth:
                error = isNullOrZero((value as number) ?? null);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.bluetoothTimeout:
                error = isNullOrZero((value as number) ?? null);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.serverUrl:
                error = isNullOrEmptyString((value as string) ?? '');
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.timeServerUrl:
                error = isNullOrEmptyString((value as string) ?? '');
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.timeServerType:
                error = isNull(value as number | null);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.timeServerPort:
                error = isDeepgridNullorDeepgridZero((value as number) ?? 0, timeType ?? 0);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.timeServerProtocol:
                error = isDeepgridNullorDeepgridEmpty((value as string) ?? '', timeType ?? 0);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.protectionEarthFrequencyF1:
                error = isGreaterThanOrLowerThan((value as number) ?? 0);
                return {
                    error: error,
                    message: error ? 'error_text' : ''
                };
            case ConfigurationProfileModelKeys.protectionEarthFrequencyF2:
                error = isGreaterThanOrLowerThan((value as number) ?? 0);
                return {
                    error: error,
                    message: error ? 'error_text' : ''
                };
            case ConfigurationProfileModelKeys.protectionEarthFrequencyF3:
                error = isGreaterThanOrLowerThan((value as number) ?? 0);
                return {
                    error: error,
                    message: error ? 'error_text' : ''
                };
            case ConfigurationProfileModelKeys.serviceEarthFrequencyF1:
                error = isGreaterThanOrLowerThan((value as number) ?? 0);
                return {
                    error: error,
                    message: error ? 'error_text' : ''
                };
            case ConfigurationProfileModelKeys.serviceEarthFrequencyF2:
                error = isGreaterThanOrLowerThan((value as number) ?? 0);
                return {
                    error: error,
                    message: error ? 'error_text' : ''
                };
            case ConfigurationProfileModelKeys.serviceEarthFrequencyF3:
                error = isGreaterThanOrLowerThan((value as number) ?? 0);
                return {
                    error: error,
                    message: error ? 'error_text' : ''
                };
            case ConfigurationProfileModelKeys.emailServerUrl:
                error = isEmailEnabledAndEmptryString((value as string) ?? '', emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.emailUsername:
                error = isEmailEnabledAndEmptryString((value as string) ?? '', emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.emailPassword:
                error = isEmailEnabledAndEmptryString((value as string) ?? '', emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.emailSubjectExpression:
                error = isEmailEnabledAndEmptryString((value as string) ?? '', emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.emailBodyExpression:
                error = isEmailEnabledAndEmptryString((value as string) ?? '', emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };

            case ConfigurationProfileModelKeys.maxChecksEmail:
                error = isGreaterThanLowerThanChecks((value as number) ?? null, emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? 'error_text_checks' : ''
                };
            case ConfigurationProfileModelKeys.checkIntervalEmail:
                error = isGreaterThanLowerThanChecks((value as number) ?? null, emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? 'error_text_checks' : ''
                };
            case ConfigurationProfileModelKeys.serverEmailPort:
                error = isGreaterThanLowerThanChecks((value as number) ?? null, emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? 'error_server_port' : ''
                };
            case ConfigurationProfileModelKeys.proxyUrl:
                error = isProxyEnabledStringEmpty((value as string) ?? '', proxyEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.proxyPort:
                error = isProxyEnabledGreaterLowerThan((value as number) ?? -1, proxyEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.dnsServerName1:
                error = isDnsEnabledStringEmpty((value as string) ?? '', customDnsEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.emailServerType:
                error = isEmailEnabledServerTypeExist((value as number) ?? null, emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };
            case ConfigurationProfileModelKeys.encryptionType:
                error = isEmailEnabledEncryptionTypeExist((value as number) ?? null, emailEnabled ?? false);
                return {
                    error: error,
                    message: error ? `${id}_required` : ''
                };

            default:
                return { error: false, message: '' };
        }
    };

    return (array[array.findIndex((x) => x.name == id)] = {
        ...array[array.findIndex((x) => x.name == id)],
        error: validateValues().error,
        message: validateValues().message
    });
};

export function ValidateConfigurationProfile(configProfile: IConfigurationProfile): IValidations[] {
    for (const [key, value] of Object.entries(configProfile).filter(([key]) => key !== 'id')) {
        ValidateConfigurationProfileValue(
            defaultValidationConfigurationProfile,
            key as ConfigurationProfileModelKeys,
            value !== undefined || value !== null ? (value as string) : null,
            configProfile.timeServerType ?? undefined,
            configProfile.emailEnabled,
            configProfile.proxyEnabled,
            configProfile.customDnsEnabled
        );
    }
    return defaultValidationConfigurationProfile;
}

export function isConfigurationProfileValid(configProfile: IConfigurationProfile): boolean {
    return ValidateConfigurationProfile(configProfile).filter((x) => x.error).length === 0;
}
