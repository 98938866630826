import { Alert, Snackbar } from '@mui/material';
import { blue, green, red, yellow } from '@mui/material/colors';
import MessageType from 'models/enums/MessageTypes';
import { useMemo } from 'react';

export default function SnackbarAlert(props: { type: MessageType; message: string; open: boolean; onClose: () => void }) {
    const { message, open, type, onClose } = props;

    const severityColor = useMemo(() => {
        switch (type) {
            case MessageType.SUCCESS:
                return 'success';
            case MessageType.ERROR:
                return 'error';
            case MessageType.WARNING:
                return 'warning';
            case MessageType.INFO:
                return 'info';
            default:
                undefined;
        }
    }, [type]);

    const bgColor = useMemo(() => {
        switch (type) {
            case MessageType.SUCCESS:
                return green[500];
            case MessageType.ERROR:
                return red[500];
            case MessageType.WARNING:
                return yellow[500];
            case MessageType.INFO:
                return blue[500];
            default:
                undefined;
        }
    }, [type]);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            autoHideDuration={6000}
            onClose={onClose}
            open={open}
            sx={{ width: 'auto', maxWidth: '40vw' }}
        >
            <Alert onClose={onClose} severity={severityColor} sx={{ width: '100%', bgcolor: bgColor, color: 'white' }} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    );
}
