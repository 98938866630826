export interface IContact {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    url?: string;
    createdBy?: string;
    createdDate?: Date;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
}

export enum ContactModelKeys {
    id = 'id',
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    phone = 'phone',
    url = 'url',
    createdBy = 'createdBy',
    createdDate = 'createdDate',
    lastModifiedBy = 'lastModifiedBy',
    lastModifiedDate = 'lastModifiedDate'
}

export function getContactFullName(contact: IContact): string {
    return `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`;
}
