import { MapMarker } from '_global/Components/base/map/logic/MapTypes';
import { SelectChangeEvent } from '@mui/material';
import { AlarmStandardList } from 'AppContext/Alarms/AlarmProfiles/Models/AlarmStandard';
import { Device } from 'models/asset/AssetDevice';
import { CATEGORY } from 'models/asset/AssetHistoricalData';
import { ASSET_TYPE_PROTECTED } from 'models/asset/AssetInfo';
import { AssetModel, Feeder, GenericFeederType } from 'models/asset/AssetModel';
import { CableTypeResponse } from 'models/cables/CableTypeResponse';
import { NewSimpleLastData } from 'models/dataStorage/NewSimpleLastData';
import { ProtectionEarthResponse } from 'models/dataStorage/ProtectionEarth';
import Mode from 'models/enums/ModeEnum';
import Type from 'models/enums/TypeEnum';
import { HVSubstations_assets_nodes } from 'queries/Types/HVSubstations';
import { operationalAreas_operationalAreas_nodes } from 'queries/Types/operationalAreas';
import { RegionList_regions_nodes, RegionList_regions_nodes_operationalAreas } from 'queries/Types/RegionList';
import { ChangeEvent } from 'react';
import { IOpAreas } from 'views/OpAreas/Models/OpAreasModel';
import { IValidations } from 'views/Users/utils/UsersModels';

export type IFeederTableHead = {
    mode: Mode;
    feederType: GenericFeederType;
};

export interface ICategory {
    onChange: (value: CATEGORY) => void;
    category: CATEGORY;
    assetModel?: AssetModel;
    hasLastData: boolean;
}

export interface IHistoricalDataFilters {
    asset: AssetModel;
    category: CATEGORY;
    clearFilter: boolean;
    onFiltersChange: () => void;
    onConfirmPressed: boolean;
    onSaveGrafanaFilter: (filters: string) => void;
}

export interface BasicDataModel {
    id: number;
    name: string;
}

export interface MapDataModel {
    initialPoint: MapMarker;
    centerPoint: MapMarker;
    operationalArea: IOpAreas | null;
}

export interface AssetDetailsProps {
    assetId?: number;
}

export interface LastDataTitleProps {
    lastData?: NewSimpleLastData;
    protectionEarth?: ProtectionEarthResponse;
    isLoading?: boolean;
    title: string;
    onRefresh: () => void;
    onExportData: () => void;
}

export interface ResponseType {
    id: number;
    name: string;
    aquisitionRate: number;
    communicationRate: number;
}

export interface DevicesTableProps {
    assetDetails: AssetModel;
    mode: Mode;
    fetchAsset: () => void;
}
export interface DevicesTableRowProps {
    device: Device;
    fetchAsset: () => void;
}

export type DevicesTableBodyProps = {
    data: Device[];
    fetchAsset: () => void;
};

export const enum LastDataOptions {
    GLOBAL = 'global',
    EARTH = 'earth'
}
export interface ILastDataStatusRequest {
    global: boolean;
    earth: boolean;
}

export interface InfoDisplayAddProps {
    assetDetails: AssetModel;
    assetInfoData: AssetInfoDataModel;
    handleChangeNumber?: ((newValue: number, id: string) => void) | undefined;
    handleOnChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void;
    handleChange?: (newValue: string, id: string) => void;
    handleChangeAssetType?: (newValue: ASSET_TYPE_PROTECTED) => void;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    opAreas: IOpAreas[];
    handleChangeBool?: (newValue: boolean, id: string) => void;
}

export interface AssetInfoDataModel {
    assetType: BasicDataModel[];
    assetParent: BasicDataModel[];
    assetFeeder: BasicDataModel[];
    optionsHvSubstation: HVSubstations_assets_nodes[] | null | undefined;
    optionsHvFeeder: BasicDataModel[];
    optionsRegions: (RegionList_regions_nodes | null)[] | null | undefined;
    optionsOpArea: RegionList_regions_nodes_operationalAreas[] | (operationalAreas_operationalAreas_nodes | null)[] | null | undefined;
    opAreaSelected: MapDataModel;
}

export interface AssetProps {
    assetDetails: AssetModel;
    handleOnChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void;
    handleOnChangeTransfomerInfo?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void;
    handleChange?: (newValue: string, id: string) => void;
    handleChangeTransfomerInfo?: (newValue: string, id: string) => void;
    handleChangeNumber?: (newValue: number, id: string) => void;
    handleChangeBulk?: (equipBulkConfigurationId: number | null, equipBulkConfigurationName: string | null) => void;
    updateFeeders?: (feeders: Feeder[]) => void;
    handleChangeAlarm?: (alarmStandardId: number | null, alarmStandardName: string | null) => void;
    handleChangeAssetType?: (newValue: ASSET_TYPE_PROTECTED) => void;
    handleChangeBool?: (newValue: boolean, id: string) => void;
}
export interface IFeederTable {
    feeders: Feeder[];
    updateFeeders: (feeders: Feeder[]) => void;
    feederType: GenericFeederType;
}

export type IFeederTableRow = {
    feeder: Feeder;
    cableTypeOptions: CableTypeResponse[];
    setFeederToDelete: (value: number) => void;
    handleOnChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string, index: number, type: Type) => void;
    handleChange: (newValue: number | null, id: string, index: number) => void;
    handleChangeSelect: (event: SelectChangeEvent, id: string, index: number, type: Type) => void;
    handleEraseCableTypeAndFuseRating: (index: number) => void;
    standardsOption: AlarmStandardList[];
    setOpenModal: (value: boolean) => void;
    mode: Mode;
};

export type IFeederTableBody = {
    mode: Mode;
    feeders: Feeder[];
    cableTypeOptions: CableTypeResponse[];
    setFeederToDelete: (value: number) => void;
    handleChange: (newValue: number | null, id: string, index: number) => void;
    handleOnChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string, index: number, type: Type) => void;
    handleChangeSelect: (event: SelectChangeEvent, id: string, index: number, type: Type) => void;
    standardsOption: AlarmStandardList[];
    setOpenModal: (value: boolean) => void;
    handleEraseCableTypeAndFuseRating: (index: number) => void;
};

export interface AssetTypeSettingsProps {
    assetDetails: AssetModel;
    assetInfoData: AssetInfoDataModel;
    mode: Mode;
    handleChangeNumber?: ((newValue: number, id: string) => void) | undefined;
    handleChange?: (newValue: string, id: string) => void;
    handleChangeAssetType?: (newValue: ASSET_TYPE_PROTECTED) => void;
}

export interface SIMInformationImportDataModel {
    iccid: BasicDataModel[];
    imsi: BasicDataModel[];
    apnName: BasicDataModel[];
    apnId: BasicDataModel[];
    apnPassword: BasicDataModel[];
    apnIpAddress: BasicDataModel[];
}

export interface IImportValidation {
    valid: boolean;
    message: string;
}

export const defaultValidationSimInformationForm = [
    {
        name: 'iccid',
        error: false,
        message: ''
    },
    {
        name: 'imsi',
        error: false,
        message: ''
    },
    {
        name: 'apnIp',
        error: false,
        message: ''
    },
    {
        name: 'apnName',
        error: false,
        message: ''
    },
    {
        name: 'apnUser',
        error: false,
        message: ''
    },
    {
        name: 'apnPwd',
        error: false,
        message: ''
    },
    {
        name: 'batchSimId',
        error: false,
        message: ''
    }
];

export const defaultCheckImportSIMs = [
    { name: 'iccid', error: false, message: '' },
    { name: 'imsi', error: false, message: '' },
    { name: 'apnName', error: false, message: '' },
    { name: 'apnId', error: false, message: '' },
    { name: 'apnPassword', error: false, message: '' },
    { name: 'apnIpAddress', error: false, message: '' }
] as IValidations[];

export const defaultImportSIMInformationValidation: IImportValidation = { valid: true, message: '' };
